const SentryCommonConfig = {
  dsn: 'https://8d705ce9f36142439534d37dca01bef4@sentry.haiper.ai/14',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,

  // allowUrls: [/haiper\.ai/],
  denyUrls: [/localhost/],
  // ...

  ignoreErrors: [
    'ResizeObserver loop completed with undelivered notifications',
    'ResizeObserver loop limit exceeded',
    'Can\'t find variable: logMutedMessage',
    'Java object is gone',
    'Failed to execute \'removeChild\' on \'Node\': The node to be removed is not a child of this node.',
    /Loading chunk [^\s]+ failed/,
    'fbq is not defined',
    'zaloJSV2',
    'Java bridge method invocation error',
  ],

  // release: process.env.NEXT_PUBLIC_BUILD_ID,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
}

export default SentryCommonConfig
